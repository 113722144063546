<template>
  <div
    ref="refSwiper"
    class="reviews-mobile-swiper swiper swiper-virtual swiper-initialized swiper-horizontal swiper-android swiper-watch-progress"
    :counter="counter"
  >
    <div class="swiper-wrapper">
      <div
        v-for="(review) in reviews"
        :key="`review-${review.id}`"
        class="swiper-slide"
      >
        <ReviewCard
          :is-manager="isManager"
          :item="review"
        />
      </div>
    </div>
    <div
      ref="refSwiperPagination"
      class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"
    />
  </div>
</template>

<script setup lang="ts">
import Swiper from 'swiper';
import { Pagination, Virtual } from 'swiper/modules';

const ReviewCard = defineAsyncComponent(() => import('~/components/reviews/ReviewCard.vue'));

interface IProps {
	reviews: IReviewItem[];
	isManager: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  reviews: () => [],
  isManager: false
});

const counter: Ref<number> = ref(0);
const refSwiper: any = ref(null);
const refSwiperPagination: any = ref(null);
const initSwiper = () => {
  const _itemSwiper = new Swiper(refSwiper.value, {
    modules: [Pagination, Virtual],
    pagination: {
      el: refSwiperPagination.value,
      enabled: true,
      type: 'bullets'
    },
    // virtual: {
    //   cache: true,
    //   enabled: true
    // },
    init: true,
    spaceBetween: 16,
    slidesPerView: 1,
    lazyPreloadPrevNext: 1,
    breakpoints: {
      640: {
        slidesPerView: 2
      }
    }
  });

  counter.value = counter.value + 1;
};
onMounted(() => {
  console.log('asdasd')
  initSwiper();
});

</script>

<style lang="scss">
.reviews-mobile-swiper {
	margin: 0 -20px;
	padding: 0 20px;
	box-sizing: border-box;

	.swiper-pagination {
		position: relative;
		margin-top: 34px;

		& > * {
			opacity: 1;
			width: 10px !important;
			height: 10px !important;
			background-color: #EBEBEB;

			&.swiper-pagination-bullet-active {
				background-color: black;
			}
		}
	}
}
</style>
